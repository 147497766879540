<template>
  <HeadlessTransitionRoot as="template" :show="isOpen" appear>
    <HeadlessDialog as="div" class="relative z-10" @close="closeModal">
      <HeadlessTransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25"></div>
      </HeadlessTransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center lg:py-8">
          <HeadlessTransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <HeadlessDialogPanel
              class="relative flex min-h-[100px] w-full transform flex-col overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:min-h-[200px] sm:w-fit sm:min-w-[50%] sm:max-w-screen-lg sm:p-6"
            >
              <div class="absolute right-0 top-0 pr-4 pt-5 sm:pt-6">
                <button
                  type="button"
                  class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-meevo-blue-500 focus:ring-offset-2"
                  @click="closeModal"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <LoadingSpinner v-if="!document && isLoading" class="grow" />
              <div v-if="document && !isLoading" class="flex grow flex-col">
                <HeadlessDialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900">
                  <PrismicRichText :field="headline" />
                </HeadlessDialogTitle>
                <PrismicRichText v-if="pageType === 'cms-page' && text" class="grow" :field="text" />
              </div>
              <div v-if="!document && !isLoading" class="mt-12 rounded-md bg-red-50 p-4">
                <div class="flex">
                  <div class="shrink-0">
                    <ExclamationCircleIcon class="h-6 w-6 text-red-500" />
                  </div>
                  <div class="ml-3">
                    <h3 class="text-sm font-medium text-red-800">
                      Der gewünschte Inhalt konnte nicht geladen werden. Bitte versuche es später erneut.
                    </h3>
                  </div>
                </div>
              </div>
              <div class="mt-4 sm:mt-3 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  @click="closeModal"
                >
                  Ok
                </button>
              </div>
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<script setup lang="ts">
import { XMarkIcon, ExclamationCircleIcon } from '@heroicons/vue/24/outline'
import type { PrismicDocument } from '@prismicio/types'

const { uid, pageType = 'cms-page' } = defineProps<{
  uid: string
  pageType?: string
}>()

const { client } = usePrismic()

const isOpen = defineModel({ type: Boolean, default: false })

const isLoading = ref(false)
const document = ref<PrismicDocument | undefined>(undefined)

const headline = computed(() => useGet(document.value, 'data.headline'))
const text = computed(() => useGet(document.value, 'data.body[0].primary.text_block'))

const fetchPrismic = async () => {
  if (!uid || !pageType) return
  isLoading.value = true
  document.value = await client.getByUID(pageType, uid)
  isLoading.value = false
}

watch(
  () => uid,
  () => fetchPrismic(),
  { immediate: true }
)

const closeModal = () => {
  isOpen.value = false
}
</script>
